import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/Hero/Hero"

export const navData = graphql`  
query {
  allDataJson(filter: {general: {lang: {eq: "es"}}}) {
    edges {
      node {
      ...generalFields
      ...aboutFields
      ...heroFields
      ...tagsFields
      }
    }
  }
}`

const NotFoundPage = (props) => (
  <Layout nav={props.data.allDataJson.edges[0].node.navigation} >
    <Seo page={'inicio'}
      title={props.data.allDataJson.edges[0].node.general.title}
      lang={props.data.allDataJson.edges[0].node.general.lang}
      metas={props.data.allDataJson.edges[0].node.general}
      image={props.data.allDataJson.edges[0].node.about.image.childImageSharp} />
    <Hero data={props.data.allDataJson.edges[0].node.hero} />
    <div className="container my-5">
      <div className="row">
        <div className="col-12 text-center">
          <h1>{props.data.allDataJson.edges[0].node.tags.not_found_title}</h1>
          <p>{props.data.allDataJson.edges[0].node.tags.not_found_message}</p>
          <a className="btn btn-xl btn-primary" href="/" >{props.data.allDataJson.edges[0].node.tags.not_found_btn}</a>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage

